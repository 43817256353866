import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "title": "Edit Tare"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function(_ref) {
        var cancel = _ref.cancel, submit = _ref.submit;
        return [_c("a-button", {
          on: {
            "click": cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": submit
          }
        }, [_vm._v("Save")])];
      }
    }])
  }, [_c("text-input", {
    key: "code",
    attrs: {
      "label": "Truck Code",
      "disabled": true,
      "placeholder": "Type Code",
      "span": 24
    }
  }), _c("text-input", {
    key: "hiddenTare",
    attrs: {
      "hidden-field": true,
      "span": 8
    }
  }), _c("text-input", {
    key: "tare",
    attrs: {
      "label": "Truck tare",
      "placeholder": "Type Truck Tare",
      "span": 16,
      "rules": "number"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditTrucksTare"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditTrucksTare = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-truck"
  }, [_c("resource", {
    attrs: {
      "name": "grower.trucks",
      "api-url": _vm.apiUrl,
      "edit": _vm.EditTrucksTare,
      "redirect-route": "/watties-grower/trucks"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditTrucksTare",
  data() {
    return {
      EditTrucksTare,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
